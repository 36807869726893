import React, { useEffect } from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import newsStore from '../stores/newsStore';
import loadingStore from '../stores/loadingStore';

const NewsList = observer(() => {
  useEffect(() => {

    const loadData = async () => {
      try{
        loadingStore.startLoading()
        await newsStore.fetchNews();
      }catch(error){
        
      }finally{
        loadingStore.setLoading(newsStore.isLoading)
      }
    }
    loadData()
    
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {newsStore.news.map((newsItem) => (
          <Grid item xs={12} sm={6} md={4} key={newsItem.id}>
            <Card>
              {newsItem.image && (
                <CardMedia
                  component="img"
                  height="140"
                  image={newsItem.image}
                  alt={newsItem.title}
                />
              )}
              <CardContent>
                <Typography variant="h5" component="div">
                  {newsItem.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {new Date(newsItem.date).toLocaleDateString()}
                </Typography>
                <Typography variant="body1">
                  {newsItem.text.slice(0, 100)}...
                </Typography>
                <Typography variant='body2' color="text.secondary">
                    бренд: {newsItem.brand_id}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

export default NewsList;

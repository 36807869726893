import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const catalog = {
  async all(params) {
    return await axios.get(`${API_URL}/store`, {
      params: params
    })
  },
  async get(article) {
    return await axios.get(`${API_URL}/store/search/${article}`, {
    })
  },
  async search(q) {
    return await axios.get(`${API_URL}/store/search`, {
      params: { q: q },
    }
    );
  },
  async filters() {
    return await axios.get(`${API_URL}/store/filters`,
    )
  },
  async gallery() {
    return await axios.get(`${API_URL}/gallery/list`)
  }
}

export const news = {
  async all() {
    return await axios.get(`${API_URL}/news`, {
    })
  }
}

export const brands = {
  async all() {
    return await axios.get(`${API_URL}/brands`);
  },
  async get(slug) {
    return await axios.get(`${API_URL}/brands/${slug}`, {
    })
  },
  async news(slug) {
    return await axios.get(`${API_URL}/brands/${slug}/news`, {
    })
  },

}


export const fetchGetToken = async (data) => {
  return await axios.post(`${API_URL}`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

import React, { useState } from 'react';
import { Box } from '@mui/material';
import FilterPanel from '../components/FilterPanel';
import Catalog from '../components/Catalog';

const CatalogPage = () => {
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedStorage, setSelectedStorage] = useState('');

  return (
    <Box sx={{ display: 'flex', marginTop: 4 }}>
      <FilterPanel
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedStorage={selectedStorage}
        setSelectedStorage={setSelectedStorage}
      />
      <Catalog />
    </Box>
  );
};

export default CatalogPage;

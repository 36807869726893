import React, { useState } from 'react';
import { Button, Box, Menu, MenuItem, IconButton, Badge } from '@mui/material';
import { NavLink } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import authStore from '../../stores/authStore';
import { observer } from 'mobx-react-lite';

const UserBlock = observer(({ user, onLogout }) => {
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const handleUserClick = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setUserAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton color="inherit" component={NavLink} to="/favorites">
            <Badge badgeContent={authStore.favorites.length} color="secondary">
              <FavoriteBorderIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" component={NavLink} to="/comparison">
            <Badge badgeContent={authStore.comparisons.length} color="secondary">
              <CompareArrowsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" component={NavLink} to="/cart">
            <Badge badgeContent={authStore.cart.length} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
      {user ? (
        <>
          <IconButton color="inherit" onClick={handleUserClick}>
            <AccountCircleIcon />
          </IconButton>
          <Menu
            anchorEl={userAnchorEl}
            open={Boolean(userAnchorEl)}
            onClose={handleUserClose}
          >
            <MenuItem onClick={handleUserClose}>Профиль</MenuItem>
            <MenuItem component={NavLink} to="/favorites" onClick={handleUserClose}>Избранное</MenuItem>
            <MenuItem component={NavLink} to="/comparison" onClick={handleUserClose}>Сравнение</MenuItem>
            <MenuItem component={NavLink} to="/admin" onClick={handleUserClose}>Админ</MenuItem>
            <MenuItem onClick={() => { onLogout(); handleUserClose(); }}>Выйти</MenuItem>
          </Menu>
        </>
      ) : (
        <Button color="inherit" component={NavLink} to={process.env.REACT_APP_B2B_URL}>
          Вход
        </Button>
      )}
    </Box>
  );
});

export default UserBlock;

import { makeAutoObservable, runInAction } from 'mobx';
import { catalog } from '../services/api';

class CatalogStore {
  tyres = [];
  images = [];
  popularTyres = [];
  params = {};
  currentPage = 1;
  totalPages = 1;
  isLoading = false;
  error = null;
  galleryLoaded = false;

  constructor() {
    makeAutoObservable(this);
    this.fetchGetGallery();
  }

  setCurrentPage(page) {
    this.currentPage = page;
    this.fetchTyres(this.params);
  }

  async fetchGetGallery() {
    try {
      const response = await catalog.gallery();
      runInAction(() => {
        if (response.data) {
          this.images = response.data;
          this.galleryLoaded = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
      });
    }
  }

  reStructuring () {
    this.tyres.forEach(tyre => {
      tyre.img = '/base_wheel.jpg'
      if (tyre.brand){
        tyre.brand = tyre.brand.brand_name
      }
      if (tyre.type_item){
        tyre.type = tyre.type_item.type_name
      }
      if (tyre.storages){
        let quantity = 0
        let storages = []
        let price_cost = []
        let price_opt = []
        tyre.storages.forEach(storage => {
          quantity += storage.quantity
          storages.push(storage.storage.storage_name)
          price_cost.push(storage.price_cost)
          price_opt.push(storage.price_opt)
        })
        tyre.quantity = quantity
        tyre.storages = storages
        tyre.price_cost = Math.max(...price_cost)
        tyre.price_opt = Math.max(...price_opt)
      }
      
    })
  }

  async fetchTyres(params = {}) {
    this.params = params;
    this.isLoading = true;
    this.error = null;
    try {
      const response = await catalog.all({
        ...params,
        page: this.currentPage,
      });
      runInAction(() => {
        if (response.data.items) {
          const data = response.data
          this.tyres = data.items;
          this.reStructuring()
          this.totalPages = data.total_page;
          this.isLoading = false;
          // if (this.galleryLoaded) {
          //   this.updateTyreImages();
          // }
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.isLoading = false;
      });
    }
  }

  updateTyreImages() {
    this.tyres.forEach(tyre => {
      // if (tyre.pattern){
      //   const matchingImage = this.images.find(img => 
      //     tyre.pattern.toLowerCase().includes(img.title.toLowerCase())
      //   );
      //   if (matchingImage) {
      //     tyre.img = matchingImage.image;
      //     return
      //   }
      // }
      tyre.img = '/base_wheel.jpg'
    });
  }
}

const catalogStore = new CatalogStore();
export default catalogStore;

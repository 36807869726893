// src/pages/ServiceCostPage.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const ServiceCostPage = () => {
  return (
    <Container>
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Информация о стоимости услуг
      </Typography>
      <Typography variant="body1">
        Здесь будет информация о стоимости услуг.
      </Typography>
    </Box>
    </Container>
  );
};

export default ServiceCostPage;

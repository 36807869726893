import React from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, Box, Container, CardActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import authStore from '../stores/authStore';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const RotateTable = ({ data, headers }) => {
  const maxColumns = 5;

  const transformedData = headers.map((header, headerIndex) => {
    return {
      header,
      values: data.map(row => row[headerIndex])
    };
  });

  // Генерация пустых ячеек, если товаров меньше 6
  const emptyCells = Array(maxColumns - data.length).fill(<TableCell sx={{ width: '200px' }} />);

  return (
    <Container sx={{ marginTop: '12px' }}>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {transformedData[0].values.map((_, index) => (
                <TableCell sx={{ maxWidth: '200px' }} key={index}>{`Товар ${index + 1}`}</TableCell>
              ))}
              {emptyCells}
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell sx={{ maxWidth: '144px' }}>{row.header}</TableCell>
                {row.values.map((value, cellIndex) => (
                  <TableCell sx={{ maxWidth: '200px' }} key={cellIndex}>{value}</TableCell>
                ))}
                {emptyCells}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

const ComparisonPage = observer(() => {
  const headers = [
    'Артикул',
    'Изображение',
    'Название',
    'Рейтинг',
    'Цена',
    'Наличие',
    'Тип шины',
    'Характеристики',
    'Действия'
  ];

  const data = authStore.comparisons.map(tyre => [
    tyre.article,
    <Box sx={{ textAlign: 'center' }}>
      <img src={tyre.img} alt={tyre.title} style={{ width: '100px', maxHeight: '100px', objectFit: 'cover' }} />
    </Box>,
    tyre.title,
    tyre.rating,
    `${tyre.price_cost} ₽`,
    tyre.quantity,
    tyre.type,
    tyre.info.size,
    <CardActions disableSpacing>
       <IconButton color="primary" onClick={() => authStore.isFavorite(tyre) ? authStore.removeFromFavorites(tyre) : authStore.addToFavorites(tyre)}>
              {authStore.isFavorite(tyre) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton>
      <IconButton color="primary" onClick={() => authStore.isCart(tyre) ? authStore.removeFromCart(tyre) : authStore.addToCart(tyre)}>
        {authStore.isCart(tyre) ? <RemoveShoppingCartIcon /> : <AddShoppingCartIcon />}
      </IconButton>
      <IconButton color="secondary" onClick={() => authStore.removeFromComparisons(tyre)}>
        <DeleteIcon />
      </IconButton>
    </CardActions>
  ]);

  return <RotateTable data={data} headers={headers} />;
});

export default ComparisonPage;

import { makeAutoObservable, runInAction } from 'mobx';
import { brands } from '../services/api';


class BrandStore {
    brand = null
    news = []
    isLoading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchBrand(slug) {
        try {
            this.isLoading = true
            const response = await brands.get(slug);
            runInAction(() => {
                if (response.data) {
                    this.brand = response.data
                }
            })
        } catch (error) {
            runInAction(() => {
                this.error = error.message
            })
        }finally{
            this.isLoading = false
        }
    };

    async fetchNews(slug) {
        try {
            this.isLoading = true
            const response = await brands.news(slug)
            runInAction(()=>{
                if (response.data){
                    this.news = response.data
                }
            })
        } catch (error) {
            runInAction(() => {
                this.error = error.message
            })
        }finally{
            this.isLoading = false
        }
    };

}

const brandStore = new BrandStore();
export default brandStore;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Paper, Container } from '@mui/material';
import { observer } from 'mobx-react-lite';
import NewsCard from '../components/NewsCard';
import brandStore from '../stores/brandStore';
import loadingStore from '../stores/loadingStore'


const BrandPage = observer(() => {
  const { slug } = useParams();

  useEffect(() => {
    const loadData = async () => {
      try{
        loadingStore.startLoading()
        await brandStore.fetchBrand(slug)
        await brandStore.fetchNews(slug)
      } catch (error) {
        console.error("Error loading favorites", error);
      } finally {
        loadingStore.setLoading(brandStore.isLoading);
      }
    }

    loadData()
  }, [slug]);


  return (
    <Container>
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>
        {brandStore.brand?.brand_name}
      </Typography>
      {brandStore.brand?.brand_info?.image && <img src={brandStore.brand?.brand_info?.image} alt={brandStore.brand?.brand_name} style={{ maxWidth: '100%' }} />}
      <Typography variant="body1" gutterBottom>
        {brandStore.brand?.brand_info?.info}
      </Typography>
      <Typography variant="h5" gutterBottom>
        Новости бренда
      </Typography>
      <Grid container spacing={2}>
        {brandStore.news.map((newsItem) => (
          <Grid item xs={12} key={newsItem.id}>
            <NewsCard newsItem={newsItem} />
          </Grid>
        ))}
      </Grid>
    </Box>
    </Container>
  );
});

export default BrandPage;

import { makeAutoObservable, runInAction } from 'mobx';
import { news } from '../services/api';

class NewsStore {
  news = [];
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
    this.fetchNews();
  }

  async fetchNews() {
    this.isLoading = true;
    this.error = null;
    try {
      const response = await news.all();
      runInAction(() => {
        this.news = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.isLoading = false;
      });
    }
  }
}

const newsStore = new NewsStore();
export default newsStore;

// src/pages/DeliveryInfoPage.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const DeliveryInfoPage = () => {
  return (
    <Container>
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Информация о доставке
      </Typography>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" gutterBottom>
          Самовывоз со склада
        </Typography>
        <Typography variant="body1" paragraph>
          Вы можете забрать товар самостоятельно с нашего склада. Адрес склада, где есть в наличии ваш товар, появится у вас в корзине.
        </Typography>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" gutterBottom>
          Курьерская доставка
        </Typography>
        <Typography variant="body1" paragraph>
          Стоимость доставки по Санкт-Петербургу от 2000 руб.
        </Typography>
        <Typography variant="body1" paragraph>
          Стоимость доставки за пределы КАД Вы можете уточнить у наших менеджеров по телефону +7 (921) 33-33-510
        </Typography>
        <Typography variant="body1" paragraph>
          Товар доставляется в течение 1-3 суток после оплаты заказа. Также возможна доставка в день обращения при условии наличия свободных транспортных средств Поставщика.
        </Typography>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" gutterBottom>
          Доставка по России
        </Typography>
        <Typography variant="body1" paragraph>
          Доставка до терминалов транспортных компаний "ПЭК" и "Деловые линии" в Санкт-Петербурге - бесплатно.
        </Typography>
        <Typography variant="body1" paragraph>
          Межтерминальная перевозка осуществляется за счет покупателя.
        </Typography>
      </Box>
    </Box>
    </Container>
  );
};

export default DeliveryInfoPage;

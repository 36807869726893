import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CatalogPage from './pages/CatalogPage';
import BrandPage from './pages/BrandPage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import FavoritiesPage from './pages/FavoritesPage'
import ComparisonPage from './pages/ComparisonPage';
import ProductPage from './pages/ProductPage';
import Register from './pages/RegisterPage';
import NewsPage from './pages/NewsPage';
import OrderProcedurePage from './pages/OrderProcedurePage';
import ServiceCostPage from './pages/ServiceCostPage';
import DeliveryInfoPage from './pages/DeliveryInfoPage';
import ReturnPolicyPage from './pages/ReturnPolicyPage';
import PaymentMethodsPage from './pages/PaymentMethodsPage';
import CompanyDetailsPage from './pages/CompanyDetailsPage';
import CartPage from './pages/CartPage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';

function AppRoutes() {
  return (
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/contacts" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/order-procedure" element={<OrderProcedurePage />} />
        <Route path="/service-cost" element={<ServiceCostPage />} />
        <Route path="/delivery-info" element={<DeliveryInfoPage />} />
        <Route path="/return-policy" element={<ReturnPolicyPage />} />
        <Route path="/payment-methods" element={<PaymentMethodsPage />} />
        <Route path="/company-details" element={<CompanyDetailsPage />} />
        <Route path="/favorites" element={<FavoritiesPage />} />
        <Route path="/comparison" element={ <ComparisonPage />} />
        <Route path="/catalog" element={<CatalogPage />} />
        <Route path="/catalog/:type" element={<CatalogPage />} />
        <Route path="/catalog/:type/:brand" element={<CatalogPage />} />
        <Route path="/catalog/:type/:brand/:name" element={<ProductPage />} />
        <Route path="/brand/:slug" element={<BrandPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/product/:id" element={<ProductPage />} />

      </Routes>
  );
}


export default AppRoutes;

import React from 'react';
import productStore from './productStore';

const stores = {
  productStore,
};

const StoresContext = React.createContext(stores);

export const useStores = () => React.useContext(StoresContext);

export const StoresProvider = ({ children }) => (
  <StoresContext.Provider value={stores}>
    {children}
  </StoresContext.Provider>
);

import { Container, Typography } from "@mui/material"

const AboutPage = () => {
    return (
        <Container sx={{marginTop: '12px'}}>
            <Typography variant="h4">Информация о компании</Typography>
        </Container>
    )
}

export default AboutPage;
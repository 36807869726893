import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';

const brands = [
  { name: 'VK Tyres', img: '/2.jpg' },
  { name: 'OZKA', img: '1.jpg' },
  { name: 'Ling Long', img: '3.jpg' },
];

const BrandSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Box sx={{ position: 'relative', left: '50%', transform: 'translateX(-50%)', width: '95vw', top: '4px', marginBottom: 4 }}>
        <Slider {...settings}>
          {brands.map((brand, index) => (
            <Box key={index} sx={{ textAlign: 'center' }}>
              <img
                src={brand.img}
                alt={brand.name}
                style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
              />
              <Typography variant="h6">{brand.name}</Typography>
            </Box>
          ))}
        </Slider>
      </Box>
  );
};

export default BrandSlider;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography, IconButton, CardActions, Card, CardMedia, CardContent, Container } from '@mui/material';
import authStore from '../stores/authStore';
import productStore from '../stores/productStore';
import loadingStore from '../stores/loadingStore'

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CompareIcon from '@mui/icons-material/Compare';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';



const ProductPage = observer(() => {
    const { name } = useParams();
    const cartproduct = authStore.getCartItem(productStore.product);

    useEffect(() => {
        const fetchTyre = async () => {
            try {
                loadingStore.startLoading()
                await productStore.fetchProduct(name)
            } catch {

            } finally {
                loadingStore.setLoading(productStore.isLoading)
            }

        };
        fetchTyre();
    }, [name]);

    const { product, info } = productStore
    return product ? (
        <Container>
            <Box sx={{ flexGrow: 1, p: 3 }}>
                <Grid container spacing={3}>
                    <Typography variant='h3' mt={4} mb={4}>
                        {product.title}
                    </Typography>
                    <Grid product xs={12} sm={6}>

                        <Card>
                            <CardMedia
                                component="img"
                                height="240"
                                image={product.img ? product.img : '/base_wheel.jpg'}
                                alt={product.brand}
                            />
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {product.type} {product.brand} - {product.article}
                                </Typography>
                                <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <IconButton color="primary" onClick={() => authStore.isFavorite(product) ? authStore.removeFromFavorites(product) : authStore.addToFavorites(product)}>
                                            {authStore.isFavorite(product) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                        </IconButton>
                                        <IconButton color="primary" onClick={() => authStore.isCompared(product) ? authStore.removeFromComparisons(product) : authStore.addToComparisons(product)}>
                                            {authStore.isCompared(product) ? <CompareIcon /> : <CompareArrowsIcon />}
                                        </IconButton>
                                    </div>

                                    <Box sx={{ display: 'flex', alignproducts: 'center' }}>
                                        <IconButton color="primary" onClick={() => authStore.addToCart(product)}>
                                            <AddShoppingCartIcon />
                                        </IconButton>
                                        {cartproduct && (
                                            <>
                                                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                                    {cartproduct.quantity}
                                                </Typography>
                                                <IconButton color="warning" onClick={() => authStore.decrimentCart(product)}>
                                                    <RemoveShoppingCartIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>
                                </CardActions>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" component="div">
                            Характеристики:
                        </Typography>
                        <Typography variant="body1" component="div">
                            Тип: {product.type}
                        </Typography>
                        <Typography variant="body1" component="div">
                            Бренд: {product.brand}
                        </Typography>
                        <Typography variant="body1" component="div">
                            Вес: {info.weight}
                        </Typography>       
                        <Typography variant="body1" component="div">
                            Размер: {info.size}
                        </Typography>   
                        <Typography variant="body1" component="div">
                            Индексы нагрузки/скорости: {info.lsi}
                        </Typography>                
                        <Typography variant="h5" component="div">
                            Цена: {product.price_cost ? product.price_cost : product.price_opt} руб.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    ) : <Typography>{productStore.error}</Typography>
}) ;

export default ProductPage;

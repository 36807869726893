// src/pages/OrderProcedurePage.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const OrderProcedurePage = () => {
  return (
    <Container>
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Процедура заказа товара/услуги
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>1. Выбор товара:</strong> 
        Посетите наш каталог и выберите интересующие вас товары. Вы можете воспользоваться фильтрами для упрощения поиска.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>2. Добавление товара в корзину:</strong>
        Когда вы найдете товар, который хотите приобрести, нажмите кнопку "Добавить в корзину". Товар будет добавлен в вашу корзину.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>3. Оформление заказа:</strong>
        Перейдите в корзину, проверьте выбранные товары и нажмите кнопку "Оформить заказ". Вам будет предложено заполнить необходимую информацию для доставки и оплаты.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>4. Подтверждение заказа:</strong>
        После заполнения всех необходимых полей, подтвердите ваш заказ. Вы получите уведомление на указанный адрес электронной почты с деталями вашего заказа.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>5. Оплата заказа:</strong>
        После подтверждения заказа, вы будете перенаправлены на страницу оплаты. Выберите удобный для вас способ оплаты и произведите оплату.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>6. Доставка:</strong>
        После получения оплаты, мы начнем обработку вашего заказа и организуем его доставку. Вы получите уведомление о статусе доставки на вашу электронную почту.
      </Typography>
    </Box>
    </Container>
  );
};

export default OrderProcedurePage;

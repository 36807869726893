import { makeAutoObservable } from "mobx";
import {catalog} from '../services/api';

class ProductStore {
  product = {};
  info = {};
  error = ''
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  reStructuring (product) {
      if (product.brand){
        product.brand = product.brand.brand_name
      }
      if (product.type_item){
        product.type = product.type_item?.type_name
      }
      if (product.storages){
        let quantity = 0
        let storages = []
        let price_cost = []
        let price_opt = []
        product.storages.forEach(storage => {
          quantity += storage.quantity
          storages.push(storage.storage?.storage_name)
          price_cost.push(storage.price_cost)
          price_opt.push(storage.price_opt)
        })
        product.quantity = quantity
        product.storages = storages
        product.price_cost = Math.max(...price_cost)
        product.price_opt = Math.max(...price_opt)
      }
      if (product.info){
        this.info = product.info
      }
    this.product = product
    
  }

  async fetchProduct(article) {
    this.isLoading = true;
    try {
      const response = await catalog.get(article);
      const data = response.data;
      this.reStructuring(data)
    } catch (error) {
      console.error("Failed to load products", error);
      this.error = error
      this.product = {}
      this.info = {}
    } finally {
      this.isLoading = false;
    }
  }
}

const productStore = new ProductStore();
export default productStore;

import React, { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';

const CallbackForm = ({open, setOpen}) => {
  const [formValues, setFormValues] = useState({
    name: '',
    phone: '',
    email: ''
  });


  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = () => {
    console.log(formValues);
    handleClose();
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Заявка на звонок</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Имя"
            type="text"
            fullWidth
            variant="outlined"
            value={formValues.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="phone"
            label="Телефон"
            type="text"
            fullWidth
            variant="outlined"
            value={formValues.phone}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Эл. почта"
            type="email"
            fullWidth
            variant="outlined"
            value={formValues.email}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CallbackForm;

import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import './index.css'
import AppRoutes from './routes';
import { StoresProvider } from './stores';
import { BrowserRouter } from 'react-router-dom';
import NavBar from './components/layout/NavBar';
import Footer from './components/layout/Footer';

import { observer } from 'mobx-react-lite';
import authStore from './stores/authStore';
import loadingStore from './stores/loadingStore';
import { LinearProgress } from '@mui/material';

const App = observer(() => {
  return (
    <StoresProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <NavBar user={authStore.user} onLogout={() => authStore.logout()} />
          <LoadingIndicator />
          <AppRoutes />
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </StoresProvider>
  );
})

const LoadingIndicator = observer(() => {
  const { isLoading } = loadingStore;

  return isLoading ? <LinearProgress /> : null;
});

export default App;

// src/components/NewsCard.js
import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

const NewsCard = ({ newsItem }) => {
  return (
    <Card>
      {newsItem.image && (
        <CardMedia
          component="img"
          height="300"
          image={newsItem.image}
          alt={newsItem.title}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {newsItem.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {newsItem.text}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {new Date(newsItem.date).toLocaleDateString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NewsCard;

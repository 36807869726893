import React, { useEffect } from 'react';
import { Box, Grid, Typography} from '@mui/material';
import { observer } from 'mobx-react-lite';
import ProductCard from './ProductCard';
import popularTyresStore from '../stores/popularTyresStore';

const CatalogBlock = observer(() => {

  useEffect(()=>{
    popularTyresStore.fetchTyres()
  }, [])

  return (
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          Популярные шины
        </Typography>
        <Grid container spacing={2}>
          {popularTyresStore.popularTyres.map((tyre, index) => (
            <ProductCard  tyre={tyre} index={index}/>
          ))}
        </Grid>
      </Box>
  );
});

export default CatalogBlock;

import React from 'react';
import { observer } from 'mobx-react-lite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CompareIcon from '@mui/icons-material/Compare';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import authStore from '../stores/authStore';
import { Box, Grid, Paper, Typography, IconButton, CardActions } from '@mui/material';

const ProductCard = observer(({ tyre, index }) => {
  const cartItem = authStore.getCartItem(tyre);

  return (
    <Grid item xs={12} sm={6} md={3} key={index}>
      <Paper sx={{ padding: 2 }}>
        <Box sx={{ textAlign: 'center' }}>
          <img src={tyre.img ? tyre.img : '/base_wheel.jpg'} alt={tyre.title} style={{ width: '100%', maxHeight: '150px', objectFit: 'cover' }} />
        </Box>
        <Typography variant="h6">{tyre.title}</Typography>
        <Typography variant="body1">Рейтинг: {tyre.rating}</Typography>
        <Typography variant="body1">Цена: {tyre.price_cost != 0 ? tyre.price_cost : tyre.price_opt} ₽</Typography>
        <Typography variant="body1">{tyre.quantity ? 'В наличии: ' + tyre.quantity : 'Нет в наличии'}</Typography>
        <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <IconButton color="primary" onClick={() => authStore.isFavorite(tyre) ? authStore.removeFromFavorites(tyre) : authStore.addToFavorites(tyre)}>
              {authStore.isFavorite(tyre) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
            <IconButton color="primary" onClick={() => authStore.isCompared(tyre) ? authStore.removeFromComparisons(tyre) : authStore.addToComparisons(tyre)}>
              {authStore.isCompared(tyre) ? <CompareIcon /> : <CompareArrowsIcon />}
            </IconButton>
          </div>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="primary" onClick={() => authStore.addToCart(tyre)}>
              <AddShoppingCartIcon />
            </IconButton>
            {cartItem && (
              <>
                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                  {cartItem.quantity}
                </Typography>
                <IconButton color="warning" onClick={() => authStore.decrimentCart(tyre)}>
                  <RemoveShoppingCartIcon />
                </IconButton>
              </>
            )}
          </Box>
        </CardActions>
      </Paper>
    </Grid>
  );
});

export default ProductCard;

import React, { useState } from 'react';
import { Button, Box, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import filterStore from '../../stores/filterStore';


export const BottomNav = () => {
  const [catalogAnchorEl, setCatalogAnchorEl] = useState(null);
  const [brandAnchorEl, setBrandAnchorEl] = useState(null);

  const handleCatalogClick = (event) => {
    setCatalogAnchorEl(event.currentTarget);
  };

  const handleCatalogClose = () => {
    setCatalogAnchorEl(null);
  };

  const handleBrandClick = (event) => {
    setBrandAnchorEl(event.currentTarget);
  };

  const handleBrandClose = () => {
    setBrandAnchorEl(null);
  };

  return (
    <Box>
      <Button color="inherit" endIcon={<ArrowDropDownIcon />} onClick={handleCatalogClick}>
        Каталог
      </Button>
      <Menu
        anchorEl={catalogAnchorEl}
        open={Boolean(catalogAnchorEl)}
        onClose={handleCatalogClose}
      >
        {filterStore.typesList.map(type => (
          <MenuItem component={NavLink} to={"/catalog/" + type.id} onClick={handleCatalogClose}>
          {type.type_name}
          </MenuItem>
        ))}
      </Menu>
      <Button color="inherit" endIcon={<ArrowDropDownIcon />} onClick={handleBrandClick}>
        Бренды
      </Button>
      <Menu
        anchorEl={brandAnchorEl}
        open={Boolean(brandAnchorEl)}
        onClose={handleBrandClose}
      >
        {filterStore.brandsList.map(brand => (
          <MenuItem component={NavLink} to={"/brand/" + brand.id} onClick={handleBrandClose}>
          {brand.brand_name}
          </MenuItem>
        ))}
      </Menu>
      <Button color="inherit" component={NavLink} to="/contacts">
        Контакты
      </Button>
      <Button color="inherit" component={NavLink} to="/news">
        Новости
      </Button>
      <Button color="inherit" component={NavLink} to="/about">
        О нас
      </Button>
      
    </Box>
  );
};

export default BottomNav;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid, Paper, IconButton, Container, CardActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import authStore from '../stores/authStore';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CompareIcon from '@mui/icons-material/Compare';



const FavoritesPage = observer(() => {
  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>
        Избранное
      </Typography>
      <Grid container spacing={2}>
        {authStore.favorites.map((tyre, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper sx={{ padding: 2 }}>
              <Box sx={{ textAlign: 'center' }}>
                <img src={tyre.img} alt={tyre.article_img} style={{ width: '100%', maxHeight: '150px', objectFit: 'cover' }} />
              </Box>
              <Typography variant="h6">{tyre.title}</Typography>
              <Typography variant="body1">Рейтинг: {tyre.rating}</Typography>
              <Typography variant="body1">Цена: {tyre.price_cost ? tyre.price_cost : tyre.price_opt} ₽</Typography>
              <Typography variant="body1">Наличие: {tyre.quantity}</Typography>
              <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <IconButton color="primary" onClick={() => authStore.isCart(tyre) ? authStore.removeFromCart(tyre) : authStore.addToCart(tyre)}>
                    {authStore.isCart(tyre) ? <RemoveShoppingCartIcon /> : <AddShoppingCartIcon />}
                  </IconButton>
                  <IconButton color="primary" onClick={() => authStore.isCompared(tyre) ? authStore.removeFromComparisons(tyre) : authStore.addToComparisons(tyre)}>
                    {authStore.isCompared(tyre) ? <CompareIcon /> : <CompareArrowsIcon />}
                  </IconButton>
                </div>
                <IconButton color="secondary" onClick={() => authStore.removeFromFavorites(tyre)}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
});

export default FavoritesPage;

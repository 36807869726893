import { makeAutoObservable, runInAction } from 'mobx';
import { catalog } from '../services/api';

class FilterStore {
  brands = [];
  storages = [];
  types = [];
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
    this.fetchCatalogData();
  }

  async fetchCatalogData() {
    this.isLoading = true;
    this.error = null;
    try {
      const response = await catalog.filters();
      runInAction(() => {
        if (response.data) {
          this.brands = response.data.brands;
          // this.storages = response.data.storages;
          this.types = response.data.types;
        }
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.isLoading = false;
      });
    }
  }

  get brandsList() {
    return this.brands;
  }

  get storagesList() {
    return this.storages;
  }

  get typesList() {
    return this.types;
  }
}

const filterStore = new FilterStore();
export default filterStore;

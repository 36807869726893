import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { Box, Typography, Grid, Container } from '@mui/material';
import Warehouses from '../components/Warehouses';

const officeLocation = [59.911491, 30.317212]; // координаты офиса

const ContactPage = () => {
    return (
        <Container>
            <Box sx={{ padding: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Контакты
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Основной офис
                        </Typography>
                        <Typography variant="body1">
                            Адрес: г. Санкт-Петербург, наб. Обводного канала, д.92, лит. А, офис 215<br />
                            Телефон: 8 (812) 317-5012<br />
                            E-mail: hello@baltyre.ru<br />
                            Режим работы: Пн. – Пт.: с 9:00 до 18:00, Сб. - Вс.: с 10:00 до 18:00
                        </Typography>
                        
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <YMaps>
                            <Map defaultState={{ center: officeLocation, zoom: 10 }} width="100%" height="300px">
                                <Placemark geometry={officeLocation} properties={{ hintContent: 'Главный офис' }} />
                            </Map>
                        </YMaps>
                    </Grid>
                </Grid>
                < Warehouses />
            </Box>
        </Container>
    );
};

export default ContactPage;

import { makeAutoObservable } from 'mobx';

class LoadingStore {
  isLoading = false;
  progress = 0;

  constructor() {
    makeAutoObservable(this);
  }

  startLoading() {
    this.isLoading = true;
  }
  setLoading(isLoading) {
    this.isLoading = isLoading;
    this.progress = 0 ? isLoading == true : 100;
  }

}

const loadingStore = new LoadingStore();
export default loadingStore;

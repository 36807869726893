import React from 'react';
import { Container, Typography } from '@mui/material';

const AdminPage = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Admin Panel
      </Typography>
      {/* Добавьте компоненты для управления новостями и товарами */}
    </Container>
  );
};

export default AdminPage;

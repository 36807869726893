import React from 'react';
import { Container  } from '@mui/material';
import BrandSlider from '../components/BrandSlider';
import NewsBlock from '../components/NewsBlock';
import CatalogBlock from '../components/CatalogBlock';
// import TireSelector from '../components/TireSelector';
import Warehouses from '../components/Warehouses';

const HomePage = () => {
  return (
    <Container>
      <BrandSlider />
      <CatalogBlock />
      <NewsBlock />
      <Warehouses />
      {/* <TireSelector /> */}
      
    </Container>
  );
};

export default HomePage;

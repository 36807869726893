import React from 'react';
import { Box, Typography, Grid, Link as UiLink } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Box sx={{ textAlign: 'center', marginTop: 4, padding: 4, backgroundColor: '#f8f8f8' }}>
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()} CTT. Все права защищены.
      </Typography>
      <Grid container spacing={2} justifyContent="space-around" sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={4} md={3}>
          <Box sx={{ textAlign: 'left' }}>
            <UiLink component={Link} to='/order-procedure' color="inherit" display="block" sx={{ marginBottom: 1 }}>
              Описание процедуры заказа товара/услуги
            </UiLink>
            <UiLink component={Link} to="/service-cost" color="inherit" display="block" sx={{ marginBottom: 1 }}>
              Информация о стоимости услуг
            </UiLink>
            <UiLink component={Link} to="/delivery-info" color="inherit" display="block" sx={{ marginBottom: 1 }}>
              Информация о доставке
            </UiLink>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Box sx={{ textAlign: 'left' }}>
            <UiLink component={Link} to="/return-policy" color="inherit" display="block" sx={{ marginBottom: 1 }}>
              Описание возврата
            </UiLink>
            <UiLink component={Link} to="/payment-methods" color="inherit" display="block" sx={{ marginBottom: 1 }}>
              Способы оплаты
            </UiLink>
            <UiLink component={Link} to="/company-details" color="inherit" display="block" sx={{ marginBottom: 1 }}>
              Реквизиты организации
            </UiLink>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;

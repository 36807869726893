// src/pages/CompanyDetailsPage.js
import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container } from '@mui/material';

const CompanyDetailsPage = () => {
  return (
    <Container>
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Реквизиты компании
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Полное наименование</TableCell>
              <TableCell>Общество с ограниченной ответственностью «Стандарт Трейдинг»</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Сокращенное наименование</TableCell>
              <TableCell>ООО «Стандарт Трейдинг»</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Юридический адрес</TableCell>
              <TableCell>191023, г. Санкт-Петербург, набережная реки Фонтанки, дом 59, лит. А, помещение 7-Н, офис № 410</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ОГРН</TableCell>
              <TableCell>1247800030389</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата регистрации</TableCell>
              <TableCell>22.03.2024</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ИНН</TableCell>
              <TableCell>7840112263</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>КПП</TableCell>
              <TableCell>784001001</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ОКПО</TableCell>
              <TableCell>54241757</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ОКВЭД</TableCell>
              <TableCell>45.31; 45.31.1; 45.31.2; 45.32; 52.29</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ОКТМО</TableCell>
              <TableCell>40909000</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Руководитель</TableCell>
              <TableCell>Генеральный Вергасов Руслан Алимжанович</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Система налогообложения</TableCell>
              <TableCell>ОСНО</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h6" gutterBottom>
          Контакты
        </Typography>
        <Typography variant="body1">Телефон: +7 992 177 2179</Typography>
        <Typography variant="body1">E-mail: standard.trading2024@gmail.com</Typography>
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h6" gutterBottom>
          Банковские реквизиты
        </Typography>
        <Typography variant="body1">Расчетный счет: 40702810490320005214</Typography>
        <Typography variant="body1">БИК: 044030790</Typography>
        <Typography variant="body1">Корреспондентский счет: 30101810900000000790</Typography>
        <Typography variant="body1">Наименование банка: Банк «Санкт-Петербург»</Typography>
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h6" gutterBottom>
          Электронный документооборот
        </Typography>
        <Typography variant="body1">Оператор: СБИС («Тензор»)</Typography>
        <Typography variant="body1">Идентификатор: 2BE71ede953bf50498091c73fbcc428217c</Typography>
      </Box>
    </Box>
    </Container>
  );
};

export default CompanyDetailsPage;

import React, { useState } from 'react';
import { AppBar, Container, Toolbar, Typography, Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';

import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import BottomNav from './BottomNav';
import UserBlock from './UserBlock';
import LocationSearchNav from './LocationSearchNav';
import CallbackForm from './CallbackFrom';

const NavBar = ({ user, onLogout }) => {

  const [open, setOpen] = useState(false)

  const handleOpenCallback = () => {
    setOpen(true)
  }


  return (
    <>
      {/* Верхняя навигация */}
      <AppBar position="static" color='default'>
        <Container>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Typography
              variant="h3"
              component={NavLink}
              to="/"
              sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit', maxWidth: '500px' }}
            >
              Стандарт трейд
            </Typography>
            <LocationSearchNav />
          </Toolbar>
        </Container>
      </AppBar>

      {/* Нижняя навигация */}
      <AppBar position="static" color="default">
        <Container>
          <Toolbar>
            <BottomNav />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon sx={{ marginRight: 1 }} />
              <Typography variant="body1" sx={{ marginRight: 3 }}>
                +8 (800) 567-890
              </Typography>
              <MailIcon sx={{ marginRight: 1 }} />
              <Button color="inherit" onClick={()=>handleOpenCallback()}>
                заявка на звонок
              </Button>
            </Box>
            <CallbackForm open={open} setOpen={setOpen}/>
            <UserBlock user={user} onLogout={onLogout} />
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default NavBar;

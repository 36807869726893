// src/pages/PaymentMethodsPage.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const PaymentMethodsPage = () => {
  return (
    <Container>
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Способы оплаты
      </Typography>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" gutterBottom>
          Оплата по счету
        </Typography>
        <Typography variant="body1" paragraph>
          При оформлении заказа как юридическое лицо Вам будет выставлен счет по реквизитам, указанным при оформлении заказа.
        </Typography>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" gutterBottom>
          Безналичный расчёт
        </Typography>
        <Typography variant="body1" paragraph>
          Вы можете оплатить свой заказ онлайн с помощью банковской карты через платежный сервис компании Uniteller. После подтверждения заказа Вы будете перенаправлены на защищенную платежную страницу Uniteller, где необходимо будет ввести данные для оплаты заказа. После успешной оплаты на указанную в форме оплаты электронную почту будет направлен электронный чек с информацией о заказе и данными по произведенной оплате.
        </Typography>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" gutterBottom>
          Гарантии безопасности
        </Typography>
        <Typography variant="body1" paragraph>
          Безопасность процессинга Uniteller подтверждена сертификатом стандарта безопасности данных индустрии платежных карт PCI DSS. Надежность сервиса обеспечивается интеллектуальной системой мониторинга мошеннических операций, а также применением 3D Secure - современной технологией безопасности интернет-платежей. Данные Вашей карты вводятся на специальной защищенной платежной странице. Передача информации в процессинговую компанию Uniteller происходит с применением технологии шифрования TLS. Дальнейшая передача информации осуществляется по закрытым банковским каналам, имеющим наивысший уровень надежности.
        </Typography>
        <Typography variant="body1" paragraph>
          Uniteller не передает данные Вашей карты магазину и иным третьим лицам! Если Ваша карта поддерживает технологию 3D Secure, для осуществления платежа, Вам необходимо будет пройти дополнительную проверку пользователя в банке-эмитенте (банк, который выпустил Вашу карту). Для этого Вы будете направлены на страницу банка, выдавшего карту. Вид проверки зависит от банка. Как правило, это дополнительный пароль, который отправляется в SMS, карта переменных кодов, либо другие способы.
        </Typography>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" gutterBottom>
          Возврат
        </Typography>
        <Typography variant="body1" paragraph>
          Срок рассмотрения заявки на возврат денежных средств составляет 5 дней. Возврат денежных средств осуществляется на ту же банковскую карту, с которой производился платеж. Возврат денежных средств на карту осуществляется в срок от 5 до 30 банковских дней, в зависимости от Банка, которым была выпущена банковская карта.
        </Typography>
      </Box>
    </Box>
    </Container>
  );
};

export default PaymentMethodsPage;

import React from 'react';
import { Box, Grid, Card, CardContent, Typography, CardMedia, CardActions, Button } from '@mui/material';

const news = [
  {
    title: 'VK Tyres launches new product',
    date: '2024-06-25',
    brand: 'VK Tyres',
    content: 'VK Tyres has launched a new product that is expected to revolutionize the tyre industry...',
    image: 'news.webp',
    link: '/news/vk-tyres-launches-new-product'
  },
  {
    title: 'OZKA announces new partnership',
    date: '2024-06-20',
    brand: 'OZKA',
    content: 'OZKA has announced a new partnership with a leading automotive company to enhance their product line...',
    image:'news.webp',
    link: '/news/ozka-announces-new-partnership'
  },
  {
    title: 'Ling Long wins award',
    date: '2024-06-15',
    brand: 'Ling Long',
    content: 'Ling Long has won an award for outstanding performance in the tyre industry...',
    image:'news.webp',
    link: '/news/ling-long-wins-award'
  }
];

const NewsBlock = () => {
  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>
        Последние новости
      </Typography>
      <Grid container spacing={4}>
        {news.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                alt={item.title}
                height="140"
                image={item.image}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  {item.date}
                </Typography>
                <Typography variant="body2" noWrap>
                  {item.content}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" href={item.link}>
                  Читать далее
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default NewsBlock;

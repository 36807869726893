import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import NewsList from '../components/NewsList';

const NewsPage = () => {
    return (
        <Container>
            <Box sx={{ marginTop: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Новости
                </Typography>
                <NewsList />
            </Box>
        </Container>

    );
};

export default NewsPage;

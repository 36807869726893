import React, { useState, useEffect } from 'react';
import { Box, IconButton, TextField, MenuItem, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Popper, Paper, List, ListItem, ListItemText } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import authStore from '../../stores/authStore';
import { observer } from 'mobx-react-lite';
import { useNavigate, Link } from 'react-router-dom';
import { catalog } from '../../services/api';

const LocationSearchNav = observer(() => {
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [detectedCity, setDetectedCity] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchCityByIP = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        setDetectedCity(response.data.city);
        setOpenDialog(true);
      } catch (error) {
        console.error('Failed to fetch city by IP:', error);
      }
    };
    if (!authStore.city) {
      fetchCityByIP();
    }
  }, []);

  const handleCityChange = (event) => {
    authStore.setCity(event.target.value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (value) {
      setAnchorEl(event.currentTarget);
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
      setAnchorEl(null);
    }
  };

  const fetchSuggestions = async (query) => {
    try {
      const response = await catalog.search(query)
      setSuggestions(response.data);
    } catch (error) {
      console.error('Failed to fetch suggestions:', error);
    }
  };


  const handleDialogClose = (accept) => {
    if (accept) {
      authStore.setCity(detectedCity);
    }
    setOpenDialog(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LocationOnIcon />
      <Select
        value={authStore.city}
        onChange={handleCityChange}
        displayEmpty
        sx={{ marginLeft: 1, marginRight: 2, border: null }}
      >
        <MenuItem value="">Ваш город</MenuItem>
        <MenuItem value="St Petersburg">СПБ</MenuItem>
        <MenuItem value="Москва">Москва</MenuItem>
        <MenuItem value="Краснодар">Краснодар</MenuItem>
      </Select>
      <form style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          variant="outlined"
          size="medium"
          placeholder="поиск..."
          value={searchQuery}
          fullWidth={true}
          sx={{width: '400px'}}
          onChange={handleSearchChange}
          onBlur={() => setTimeout(() => setSuggestions([]), 100)}
        />
        <Popper open={Boolean(anchorEl && suggestions.length > 0)} anchorEl={anchorEl} style={{ zIndex: 1 }}>
          <Paper style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}>
            <List>
              {suggestions.map((suggestion) => (
                <ListItem 
                  button 
                  key={suggestion.id}
                  onMouseDown={() => {navigate(`catalog/${suggestion.type_item?.slug}/${suggestion.brand?.slug || 'other'}/${suggestion.article.trim()}`)}}
                >
                    <ListItemText primary={suggestion.title.slice(0, 30)+ '...'}  secondary={suggestion.article} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Popper>
      </form>

      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
      >
        <DialogTitle>Определение местоположения</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Это ваш город {detectedCity}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Другой
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default LocationSearchNav;

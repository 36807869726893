// src/pages/ReturnPolicyPage.js
import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const ReturnPolicyPage = () => {
  return (
    <Container>
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Политика возврата
      </Typography>
      <Typography variant="body1" paragraph>
        Срок рассмотрения заявки на возврат денежных средств составляет 5 дней. Возврат денежных средств осуществляется на ту же банковскую карту, с которой производился платеж. Возврат денежных средств на карту осуществляется в срок от 5 до 30 банковских дней, в зависимости от Банка, которым была выпущена банковская карта.
      </Typography>
    </Box>
    </Container>
  );
};

export default ReturnPolicyPage;

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import authStore from '../stores/authStore';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Register = observer(() => {
    const navigate = useNavigate()
    const [userData, setUserData] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleRegister = async () => {
        const success = await authStore.register(userData);
        if (success) {
            navigate('/login')
        } else {
            // Handle registration failure
        }
    };

    return (
        <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5 }}>
            <Typography variant="h4" component="div" gutterBottom>
                Регистрация
            </Typography>
            <TextField
                label="Email"
                name="email"
                value={userData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Пароль"
                name="password"
                type="password"
                value={userData.password}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleRegister} fullWidth sx={{ mt: 2 }}>
                Зарегистрироваться
            </Button>
        </Box>
    );
});

export default Register;

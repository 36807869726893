// src/components/Warehouses.js
import React, { useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { Container, Typography, Grid, TextField, List, ListItem, ListItemText } from '@mui/material';

const warehousesData = [
  { id: 1, name: 'Санкт-Петербург', location: [59.9311, 30.3609], address: 'Адрес СПБ', phone: '+7 921 123-45-67' },
  { id: 2, name: 'Москва', location: [55.7558, 37.6176], address: 'Адрес Москва', phone: '+7 921 123-45-67' },
  { id: 3, name: 'Краснодар', location: [45.0355, 38.9753], address: 'Адрес Краснодар', phone: '+7 921 123-45-67' },
  { id: 4, name: 'Волгоград', location: [48.708, 44.5133], address: 'Адрес Волгоград', phone: '+7 921 123-45-67' },
  { id: 5, name: 'Ростов-на-Дону', location: [47.2357, 39.7015], address: 'Адрес Ростов-на-Дону', phone: '+7 921 123-45-67' },
  { id: 6, name: 'Челябинск', location: [55.1644, 61.4368], address: 'Адрес Челябинск', phone: '+7 921 123-45-67' },
  { id: 7, name: 'Владивосток', location: [43.1155, 131.8855], address: 'Адрес Владивосток', phone: '+7 921 123-45-67' },
  { id: 8, name: 'Екатеринбург', location: [56.8389, 60.6057], address: 'Адрес Екатеринбург', phone: '+7 921 123-45-67' },
];

const Warehouses = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [mapState, setMapState] = useState({ center: [55.751244, 37.618423], zoom: 5 });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleWarehouseClick = (warehouse) => {
    setSelectedWarehouse(warehouse);
    setMapState({ center: warehouse.location, zoom: 12 });
  };

  const filteredWarehouses = warehousesData.filter((warehouse) =>
    warehouse.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Наши склады
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Поиск склада"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ mb: 2 }}
          />
          <List>
            {filteredWarehouses.map((warehouse) => (
              <ListItem
                button
                key={warehouse.id}
                onClick={() => handleWarehouseClick(warehouse)}
              >
                <ListItemText primary={warehouse.name} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={8}>
          <YMaps>
            <Map
              state={mapState}
              width="100%"
              height="600px"
            >
              {warehousesData.map((warehouse) => (
                <Placemark
                  key={warehouse.id}
                  geometry={warehouse.location}
                  properties={{
                    balloonContent: `
                      <strong>${warehouse.name}</strong><br />
                      Адрес: ${warehouse.address}<br />
                      Телефон: ${warehouse.phone}
                    `,
                  }}
                  options={{
                    preset: 'islands#icon',
                    iconColor: '#3b5998',
                  }}
                  onClick={() => handleWarehouseClick(warehouse)}
                />
              ))}
            </Map>
          </YMaps>
        </Grid>
      </Grid>
      </>
  );
};

export default Warehouses;

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Container } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import authStore from '../stores/authStore';


const CartPage = observer(() => {
    const [form, setForm] = useState({
        name: '',
        phone: '',
        email: '',
        address: ''
    });
    console.log(authStore.cart)

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.name = form.name ? '' : 'Имя обязательно';
        tempErrors.phone = form.phone ? '' : 'Телефон обязателен';
        tempErrors.email = form.email ? '' : 'Электронная почта обязательна';
        tempErrors.address = form.address ? '' : 'Адрес обязателен';
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            console.log(form);
            // Здесь можно добавить логику отправки данных на сервер
        }
    };

    const calculateTotal = () => {
        return authStore.cart.reduce((acc, { tyre, quantity }) => {
            const price = tyre.price_cost ? tyre.price_cost : tyre.price_opt
            return Math.round(acc + (price * quantity));
        }, 0);
    };

    const calculateQuantity = () => {
        return authStore.cart.reduce((acc, item) => acc + item.quantity, 0);
    };

    return (
        <Container>
            <Box>
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Корзина
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Изображение</TableCell>
                                    <TableCell>Название</TableCell>
                                    <TableCell>Цена</TableCell>
                                    <TableCell>Количество</TableCell>
                                    <TableCell>Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {authStore.cart.map(({ tyre, quantity }, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <img src={tyre.img} alt={tyre.article + '_img'} style={{ width: '100px', maxHeight: '100px', objectFit: 'cover' }} />
                                            </Box>
                                        </TableCell>
                                        <TableCell>{tyre.title}</TableCell>
                                        <TableCell>{tyre.price_cost ? tyre.price_cost : tyre.price_opt} ₽</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => authStore.decrimentCart(tyre)}>
                                                <RemoveIcon />
                                            </IconButton>
                                            {quantity}
                                            <IconButton onClick={() => authStore.addToCart(tyre)}>
                                                <AddIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton color="secondary" onClick={() => authStore.removeFromCart(tyre)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={1} />
                                    <TableCell>Итого</TableCell>

                                    <TableCell>{calculateTotal()} ₽</TableCell>
                                    <TableCell>{calculateQuantity()}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Оформление заказа
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="name"
                                    label="Имя"
                                    variant="outlined"
                                    fullWidth
                                    value={form.name}
                                    onChange={handleChange}
                                    error={!!errors.name}
                                    helperText={errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="phone"
                                    label="Телефон"
                                    variant="outlined"
                                    fullWidth
                                    value={form.phone}
                                    onChange={handleChange}
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    label="Электронная почта"
                                    variant="outlined"
                                    fullWidth
                                    value={form.email}
                                    onChange={handleChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="address"
                                    label="Адрес"
                                    variant="outlined"
                                    fullWidth
                                    value={form.address}
                                    onChange={handleChange}
                                    error={!!errors.address}
                                    helperText={errors.address}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    Оформить заказ
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
        </Container >
    );
});

export default CartPage;

import React, { useEffect } from 'react';
import { Box, Grid, Typography, Pagination } from '@mui/material';
import { observer } from 'mobx-react-lite';
import catalogStore from '../stores/catalogStore';
import { useSearchParams, useParams } from 'react-router-dom';
import loadingStore from '../stores/loadingStore'
import ProductCard from './ProductCard';

const Catalog = observer(() => {
  const { type, brand } = useParams()
  let [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page') || '1', 10);

  useEffect(() => {
    const loadData = async () => {
      loadingStore.startLoading();
      try {
        const params = Object.fromEntries(searchParams.entries());
        params['type'] = type
        params['brand'] = brand
        await catalogStore.fetchTyres(params);
      } catch (error) {
        console.error("Error loading favorites", error);
      } finally {
        loadingStore.setLoading(catalogStore.isLoading);
      }
    };

    loadData();

  }, [searchParams, brand, type]);

  const handleSetParams = (name, event) => {
    setSearchParams(params => {
      params.set(name, event.target.value)
      return params
    });
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, width: 'calc(100% - 320px)' }}>
      <Typography variant="h4" gutterBottom>
        Наш Каталог
      </Typography>
      <Grid container spacing={2}>
        {catalogStore.tyres.map((tyre, index) => (
          <ProductCard key={index} tyre={tyre} index={index} />
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Pagination
          count={catalogStore.totalPages}
          page={currentPage}
          onChange={(e) => handleSetParams('page', e)}
          color="primary"
        />
      </Box>
    </Box>
  );
});

export default Catalog;

import { makeAutoObservable } from 'mobx';
import {fetchGetToken} from '../services/api'

class AuthStore {
  isAuthenticated = false;
  user = null;
  token = null;
  favorites = [];
  comparisons = [];
  cart = [];
  city = '';

  constructor() {
    makeAutoObservable(this);
    this.loadFromLocalStorage();
  }


  async login(username, password) {
    try {
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetchGetToken(formData)
      if (response.data && response.data.access_token) {
        this.token = response.data.access_token;
        this.isAuthenticated = true;
        this.user = { username }; 
        localStorage.setItem('token', response.data.access_token);
        this.saveToLocalStorage();
        return true;
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
    return false;
  }

  logout() {
    this.isAuthenticated = false;
    this.user = null;
    this.token = null;
    this.favorites = [];
    this.comparisons = [];
    this.cart = [];
    this.city = '';
    this.saveToLocalStorage();
  }

  addToFavorites(tyre) {
    if (!this.favorites.some(fav => fav.id === tyre.id)) {
      this.favorites.push(tyre);
      this.saveToLocalStorage();
    }
  }

  removeFromFavorites(tyre) {
    this.favorites = this.favorites.filter(fav => fav.id !== tyre.id);
    this.saveToLocalStorage();
  }

  addToComparisons(tyre) {
    if (!this.comparisons.some(comp => comp.id === tyre.id)) {
      this.comparisons.push(tyre);
      this.saveToLocalStorage();
    }
  }

  removeFromComparisons(tyre) {
    this.comparisons = this.comparisons.filter(comp => comp.id !== tyre.id);
    this.saveToLocalStorage();
  }

  addToCart(tyre) {
    const cartItem = this.cart.find(item => item.tyre.id === tyre.id);
    if (cartItem) {
      if (cartItem.quantity < tyre.quantity) {
        cartItem.quantity += 1;
      }
    } else {
      this.cart.push({ tyre, quantity: 1 });
    }
    this.saveToLocalStorage();
  }

  decrimentCart(tyre) {
    const cartItem = this.cart.find(item => item.tyre.id === tyre.id);
    if (cartItem) {
      if (cartItem.quantity > 1) {
        cartItem.quantity -= 1;
      }
      else{
        this.cart = this.cart.filter(item => item.tyre.id !== tyre.id);
      }
    }
    this.saveToLocalStorage();
  }

  removeFromCart(tyre) {
    this.cart = this.cart.filter(item => item.tyre.id !== tyre.id);
    this.saveToLocalStorage();
  }

  isCart(tyre) {
    return this.cart.some(item => item.tyre.id === tyre.id);
  }

  isFavorite(tyre) {
    return this.favorites.some(fav => fav.id === tyre.id);
  }

  isCompared(tyre) {
    return this.comparisons.some(comp => comp.id === tyre.id);
  }

  getCartItem(tyre) {
    return this.cart.find(item => item.tyre.id === tyre.id);
  }

  setCity(city) {
    this.city = city;
    this.saveToLocalStorage();
  }

  saveToLocalStorage() {
    const data = {
      isAuthenticated: this.isAuthenticated,
      user: this.user,
      token: this.token,
      favorites: this.favorites,
      comparisons: this.comparisons,
      cart: this.cart,
      city: this.city,
    };
    localStorage.setItem('authStore', JSON.stringify(data));
  }

  loadFromLocalStorage() {
    const data = localStorage.getItem('authStore');
    if (data) {
      const parsedData = JSON.parse(data);
      this.isAuthenticated = parsedData.isAuthenticated;
      this.user = parsedData.user;
      this.token = parsedData.token;
      this.favorites = parsedData.favorites;
      this.comparisons = parsedData.comparisons;
      this.cart = parsedData.cart;
      this.city = parsedData.city;
    }
  }
}

const authStore = new AuthStore();
export default authStore;

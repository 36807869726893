import React from 'react';
import { Box, List, ListItem, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import filterStore from '../stores/filterStore';

const FilterPanel = observer(() => {
  const navigate = useNavigate()
  const { type, brand } = useParams()
  let [searchParams, setSearchParams] = useSearchParams();

  const selectedBrand = brand || '';
  const selectedType = type || '';
  const selectedStorage = searchParams.get('location') || '';

  const handleReset = () => {
    navigate(`/catalog/${type}`)
  }

  const handleBrandChange = (event) => {
    navigate(`/catalog/${type}/${event.target.value}`)
    };

  const handleTypeChange = (event) => {
    navigate(`/catalog/${event.target.value}`)
  };

  const handleSetParams = (name, event) => {
    setSearchParams(params => {
        params.set(name, event.target.value)
        return params
    });
  };
  return (
    <Box sx={{ width: '320px', boxSizing: 'border-box', marginTop: '12px' }}>
      <List>
        <ListItem>
          <FormControl fullWidth>
            <InputLabel id="type-label">Типы шин</InputLabel>
            <Select
              labelId="type-label"
              value={selectedType}
              label="Типы шин"
              onChange={handleTypeChange}
            >
              {filterStore.typesList.map((type) => (
                <MenuItem key={type.id} value={type.slug}>
                  {type.type_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl fullWidth>
            <InputLabel id="brand-label">Бренды</InputLabel>
            <Select
              labelId="brand-label"
              value={selectedBrand}
              label="Бренды"
              onChange={handleBrandChange}
            >
              {filterStore.brandsList.map((brand) => (
                <MenuItem key={brand.id} value={brand.slug}>
                  {brand.brand_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl fullWidth>
            <InputLabel id="location-label">Склады</InputLabel>
            <Select
              labelId="location-label"
              value={selectedStorage}
              label="Склады"
              onChange={(e)=>handleSetParams('location', e)}
            >
              {filterStore.storagesList.map((storage) => (
                <MenuItem key={storage.id} value={storage.slug}>
                  {storage.storage_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label="Цена от"
            variant="outlined"
            type="number"
            value={searchParams.get('price_gte') || ''}
            onChange={(e)=>handleSetParams('price_gte', e)}
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label="Цена до"
            variant="outlined"
            type="number"
            value={searchParams.get('price_lte') || ''}
            onChange={(e)=>handleSetParams('price_lte', e)}
          />
        </ListItem>
        <ListItem>
          <Button variant="contained" color="info" fullWidth onClick={handleReset}>
            сбросить фильтры
          </Button>
        </ListItem>
      </List>
    </Box>
  );
});

export default FilterPanel;
